import React from 'react'
import './style.scss'
import { useStaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'
//import NaviContext from '../../../context/NaviContext'

function Logo(props) {
	//const naviContext = useContext(NaviContext)

	const data = useStaticQuery(graphql`
	query {
		casa: file(relativePath: { eq: "logo-casakahlo.png" }) {
			childImageSharp {
				fluid (
					maxWidth: 1280,
					quality: 70,
					srcSetBreakpoints: [960,1280,1920,2560]
				) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
	}
`)
const casa = data.casa?.childImageSharp.fluid

  return (
		<div id="master-logo" className='logo'>
			<Img fluid={casa}
				imgStyle={{objectFit: 'contain'}}
				objectPosition='50% 50%'
				loading='eager'
			/>
		</div>
  )
}

export default Logo