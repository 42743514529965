import React from 'react'
import './style.scss'
//import NaviContext from '../../context/NaviContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faAirbnb } from '@fortawesome/free-brands-svg-icons'

function SocialIcons(props) {
	//const naviContext = useContext(NaviContext)
	
  return (
		<>
			<div className='social-icons'>
				{/* <div className='social-icon hint--rounded hint--bottom' data-hint='FaceBook'>
					<a href='https://facebook.com' target='_blank' rel='noopener noreferrer'>
						<FontAwesomeIcon icon={faFacebookSquare} />
					</a>
				</div> */}
				<div className='social-icon hint--rounded hint--bottom' data-hint='FaceBook'>
					<a href='https://www.facebook.com/FridaKahloCorporation' target='_blank' rel='noopener noreferrer'>
						<FontAwesomeIcon icon={faFacebook} />
					</a>
				</div>
				{/* <div className='social-icon hint--rounded hint--bottom' data-hint='YouTube'>
					<a href='https://youtube.com' target='_blank' rel='noopener noreferrer'>
						<FontAwesomeIcon icon={faYoutube} />
					</a>
				</div> */}
				<div className='social-icon hint--rounded hint--bottom' data-hint='Instagram'>
					<a href='https://instagram.com/realFridaKahlo' target='_blank' rel='noopener noreferrer'>
						<FontAwesomeIcon icon={faInstagram} />
					</a>
				</div>
				<div className='social-icon hint--rounded hint--bottom' data-hint='AirBnB'>
					<a href='https://airbnb.com/FridaKahloCorporation' target='_blank' rel='noopener noreferrer'>
						<FontAwesomeIcon icon={faAirbnb} />
					</a>
				</div>
			</div>
		</>
  )
}

export default SocialIcons