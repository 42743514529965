import React, {useContext} from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import './style.scss'
import NaviContext from '../../context/NaviContext'
import { Transition } from 'react-transition-group'
import anime from 'animejs'
import Img from 'gatsby-image'

import Hamburger from './Hamburger'
import Navi from './Navi'
import SocialIcons from '../SocialIcons'

function Header(props) {
	const naviContext = useContext(NaviContext)

	const data = useStaticQuery(graphql`
		query {
			corp: file(relativePath: { eq: "logo-kahlocorporation.png" }) {
				childImageSharp {
					fluid (
						maxWidth: 1280,
						quality: 70,
						srcSetBreakpoints: [960,1280,1920,2560]
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
		}
	`)
	
	const corp = data.corp?.childImageSharp.fluid

	// Animating fade in/out
	const baseDuration = 250
	const fadeInLogo = element => {
		const links = element.querySelectorAll('.nav-item, .social-icons a')
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [0, 1],
				duration: baseDuration,
				easing: 'easeInOutSine',
			})
			.add({
				targets: links,
				opacity: [0, 1],
				translateX: [-22, 0],
				duration: baseDuration,
				easing: 'easeInOutSine',
				delay: anime.stagger(50)
			}, `-=${baseDuration}`)
	}
	const fadeOutLogo = element => {
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [1, 0],
				duration: baseDuration / 2,
				easing: 'linear'
			})
	}

  return (
		<header id='master-header' className={'x1 t'}>
			<Hamburger />

			{/* {naviContext.beforeHeaderBreakpoint ? 'true' :	
			naviContext.scrollingDirectionIsDown ? 'false' : 'true'} */}
			<Transition
				in={naviContext.isActive ? true :	false}
				timeout={baseDuration}
				appear={true}
				onEntering={fadeInLogo}
				onExiting={fadeOutLogo}
				mountOnEnter
				unmountOnExit
			>
				<div className={`navi-animator c3 ${naviContext.isHamburgerActive && !naviContext.beforeHeaderBreakpoint ? null : 't'}`}>

					<Navi {...props} />
					<SocialIcons />
					
						<div className="logo-kahlo-corporation">
							<Img fluid={corp}
								imgStyle={{objectFit: 'contain'}}
								objectPosition='50% 50%'
								loading='eager'
							/>
						</div>

				</div>
			</Transition>
		</header>
  )
}

export default Header